// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProductDescriptionScss from "./ProductDescription.scss";

var css = ProductDescriptionScss;

function ProductDescription(props) {
  var product = props.product;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H1.make, {
                      className: css.productTitle,
                      children: product.providerName + (" " + product.name)
                    }),
                JsxRuntime.jsx("div", {
                      children: product.description,
                      className: css.productDescription
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.map(product.features, (function (feature) {
                              return JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("div", {
                                                        children: feature.name,
                                                        className: css.featureTitle
                                                      }),
                                                  JsxRuntime.jsx("div", {
                                                        children: feature.description,
                                                        className: css.featureDescription
                                                      })
                                                ],
                                                className: css.featureMargin
                                              }),
                                          className: css.featureFlexContainer
                                        }, ID.toString(feature.id));
                            })),
                      className: css.features
                    })
              ],
              className: css.productDescriptionContainer
            });
}

var make = ProductDescription;

export {
  css ,
  make ,
}
/* css Not a pure module */
